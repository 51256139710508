import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {  Col, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { searchProduct} from "../../store/actions";

import ObjectColumns from "./ObjectColumnsSearch";

const Arrived = props => {
    const dispatch = useDispatch();
    const [selectProducts, setSelectProducts] = useState([])

    useEffect(() => {
        let item = {
            id: props.match.params.id,
        }
        dispatch(searchProduct(item))
    }, [props.match.params.id])


    function onRowSelect(row, isSelected) {

        if (isSelected) {
            setSelectProducts(prevArray =>
                [...prevArray, row._id]
            )
        } else {
            setSelectProducts(selectProducts.filter(item => item !== row._id))
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="ძებნა"/>
                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                onRowSelect={onRowSelect}
                                logisticProducts={props.products.products}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = state => {
    const {
        products,
        logistic
    } = state
    return {
        products,
        logistic
    }
};
export default withRouter(connect(mapStateToProps)(Arrived));
